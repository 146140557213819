export const TOKEN_NAME = "token";

export function isLoggedIn(): boolean {
  const token = localStorage.getItem(TOKEN_NAME);
  return !!token;
}

export function getToken(): string | null {
  if (!isLoggedIn()) {
    return null;
  }
  const token = localStorage.getItem(TOKEN_NAME);
  let parsedToken = token && JSON.parse(token);
  return extractJSessionId(parsedToken.cookie);
}

function extractJSessionId(cookieString: string) {
  const cookies = cookieString.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith("JSESSIONID=")) {
      return cookie;
    }
  }
  return null;
}

export function getUserId(): string | null {
  if (!isLoggedIn()) {
    return null;
  }
  const token = localStorage.getItem(TOKEN_NAME);
  let parsedToken = token && JSON.parse(token);
  return parsedToken.id;
}

function checkCookieProperty(obj: any): boolean {
  if (Object.keys(obj)[0] === "cookie") {
    let match = obj.cookie.match(/^JSESSIONID=([A-F0-9]{32});.*/);
    return match !== null;
  }
  return false;
}

export function stringToDecimal(str: string) {
  if (str.includes(',')) {
    str = str.replace(/,/g, '.');
  }
  return parseFloat(str);
}

export function extractFilename(url: string) {
  return url.split('/').pop();
}
