import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
// @ts-ignore
import VitalityExperienceLogo from '../../assets/img/vitality-experience-logo.png';
// @ts-ignore
import KulpLogo from '../../assets/img/klup-logo.png';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Hero from '../Hero';
import axios, { AxiosError } from 'axios';
import { extractFilename, getToken } from '../../utils/helpers';
import SearchPlace from '../SearchPlace';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
import { getDateFromZone } from '../../utils/dateHelpers';
import { useIsMobile } from '../../hooks/useIsMobile';
import { SubmitButton } from '../shared/submitButton/SubmitButton';

const TextErrorMessage = (props: any) => {
  return <p className="note-text-light pl-4 pt-2">{props.children}</p>;
};

const CustomInputComponent = (props: any) => (
  <input
    className={`border ${
      props.errorstate ? 'border-accent-secondary' : 'border-accent-primary'
    } rounded-xl py-4 px-4 text-sm text-tertiary w-full focus:outline-none border-1 focus:border-blue-700`}
    type={props.type}
    {...props}
  />
);

const InputField = (props: any) => {
  const { label, name, type, errorstate, ...rest } = props;
  return (
    <div>
      <label className="note-text-light text-baseTonBlue900" htmlFor={name}>
        {label}
      </label>
      <Field type={type} name={name} id={name} as={CustomInputComponent} errorstate={errorstate} {...rest} />
      <ErrorMessage name={name} component={TextErrorMessage} />
    </div>
  );
};

const CreateActivityForm = () => {
  const isMobile = useIsMobile();
  const [activity, setActivity] = useState<any>();
  const [error, setError] = useState<string | null>(null);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState('');

  const [endDate, setEndDate] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState('');
  const [timeError, setTimeError] = useState<string | null>(null);

  const [noEndDate, setNoEndDate] = useState(false);

  const [isActivityDataLoaded, setIsActivityDataLoaded] = useState(false);

  const { activityId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location.pathname.includes('/edit');

  const formik = useFormik({
    initialValues: {
      name: '',
      interestIds: [0],
      description: '',
      benefit: '',
      location: '',
      address: '',
      lng: 0,
      lat: 0,
      datetimeStart: '',
      datetimeEnd: '',
      photo: '',
      photoFile: '',
      maxPeople: 0,
      vitalityExperience: false,
    } as {
      name: string;
      interestIds: number[];
      description: string;
      benefit: string;
      location: string;
      address: string;
      lng: number;
      lat: number;
      datetimeStart: string;
      datetimeEnd: string;
      photo: string;
      photoFile: string | File | null;
      maxPeople: number;
      vitalityExperience: boolean;
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      interestIds: Yup.array().of(Yup.number()).min(1).max(3).required(),
      description: Yup.string().min(199).max(3999, 'Het veld mag niet meer dan 4000 tekens bevatten.').required(),
      // Temp until backend production release fix, max 200.
      benefit: Yup.string().max(199, 'Het veld mag niet meer dan 200 tekens bevatten.').required(),
      location: Yup.string().required(),
      address: Yup.string().required(),
      lng: Yup.number().required(),
      lat: Yup.number().required(),
      datetimeStart: Yup.string().required(),
      photo: Yup.mixed().required(),
      photoFile: Yup.mixed().test('fileSize', 'De bestandsgrootte moet kleiner zijn dan 2 MB', (file: any) => {
        const isValidSize = typeof file === 'string' ? true : file?.size < 2097152;
        return isValidSize;
      }),
      maxPeople: Yup.number().min(2).required(),
      vitalityExperience: Yup.boolean().required(),
    }),
    onSubmit: () => {
      createActivity();
    },
  });

  const interestsItems = [
    {
      id: 41,
      name: 'Kunst & Cultuur',
      active: true,
    },
    {
      id: 42,
      name: 'Borrelen',
      active: true,
    },
    {
      id: 44,
      name: 'Reizen',
      active: true,
    },
    {
      id: 45,
      name: 'Uit eten',
      active: true,
    },
    {
      id: 50,
      name: 'Wandelen',
      active: true,
    },
    {
      id: 51,
      name: 'Film',
      active: true,
    },
    {
      id: 55,
      name: 'Overig',
      active: true,
    },
    {
      id: 56,
      name: 'Dansen',
      active: true,
    },
    {
      id: 57,
      name: 'Fietsen',
      active: true,
    },
    {
      id: 58,
      name: 'Workshop',
      active: true,
    },
  ];

  const radioOptions = [
    {
      key: 'Vitality Experience',
      value: true,
      logo: VitalityExperienceLogo,
    },
    {
      key: 'Klup Partner',
      value: false,
      logo: KulpLogo,
    },
  ];

  useEffect(() => {
    const getActivityByIdData = async () => {
      const url = `${process.env.REACT_APP_API_URL}api/activity/${activityId}`;

      try {
        axios.defaults.withCredentials = true;

        let config = {
          method: 'get',
          origin: true,
          credentials: true,
          url: url,
          headers: {
            'content-Type': 'application/json',
          },
        };

        axios
          .request(config)
          .then((response) => {
            setActivity(response.data);
            formik.setValues({
              name: response.data.name || '',
              interestIds: response.data.interests.map((item: any) => item.id) || [],
              description: response.data.description || '',
              benefit: response.data.benefit || '',
              location: response.data.location || '',
              address: response.data.address || '',
              lng: response.data.lng || 0,
              lat: response.data.lat || 0,
              datetimeStart: response.data.datetimeStart || '',
              datetimeEnd: response.data.datetimeEnd || '',
              photo: response.data.photo || '',
              photoFile: response.data.photo || '',
              maxPeople: response.data.maxPeople || 0,
              vitalityExperience: response.data.vitalityExperience || false,
            });

            setStartDate(response.data.datetimeStart ? new Date(response.data.datetimeStart) : null);
            setEndDate(response.data.datetimeEnd ? new Date(response.data.datetimeEnd) : null);

            const timeStringStart = response.data.datetimeStart.split('T')[1].substring(0, 5);
            setStartTime(timeStringStart);

            const timeStringEnd = response.data.datetimeEnd?.split('T')[1].substring(0, 5);
            setEndTime(timeStringEnd ?? null);

            if (formik.values.datetimeEnd == null || timeStringEnd == null) {
              setNoEndDate(true);
            }

            setIsActivityDataLoaded(true);
          })
          .catch((error) => {
            console.error('Error fetching data: ', error);
          });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    if (!isActivityDataLoaded && isEditMode) {
      getActivityByIdData();
    }
  }, []);

  useEffect(() => {
    if (!isActivityDataLoaded) {
      formik.setFieldValue('interestIds', []);
    }
  }, []);

  useEffect(() => {
    const formattedDate = getDateFromZone(new Date(startDate!));
    formik.setFieldValue('datetimeStart', formattedDate + 'T' + startTime);
  }, [startDate, startTime]);

  useEffect(() => {
    const formattedDate = getDateFromZone(new Date(endDate!));
    formik.setFieldValue('datetimeEnd', formattedDate + 'T' + endTime);
  }, [endDate, endTime]);

  useEffect(() => {
    formik.setFieldValue('datetimeEnd', null);
  }, [noEndDate]);

  const uploadPhoto = async (file: File) => {
    if (!file) {
      console.error('No file selected.');
      return;
    }

    const data = new FormData();
    data.append('images', file);

    const url = `${process.env.REACT_APP_URL}api/image?imageType=BACKGROUND`;

    try {
      axios.defaults.withCredentials = true;

      const response = await axios.post(url, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const photoName = response.data[0];
      return photoName;
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNoEndDate(event.target.checked);
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (val.length === 2) {
      setStartTime(val + ':');
    } else {
      setStartTime(val);
    }
    e.preventDefault();
  };

  const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (val.length === 2) {
      setEndTime(val + ':');
    } else {
      setEndTime(val);
    }
    e.preventDefault();
  };

  const handlePlaceChange = (selectedPlace: google.maps.places.PlaceResult) => {
    const address = selectedPlace.formatted_address;
    const geometry = selectedPlace.geometry;

    formik.setFieldValue('location', address);
    formik.setFieldValue('address', address);
    formik.setFieldValue('lat', geometry?.location?.lat());
    formik.setFieldValue('lng', geometry?.location?.lng());
  };

  const createActivity = async () => {
    const url = `${process.env.REACT_APP_API_URL}api/activity`;

    try {
      axios.defaults.withCredentials = true;

      let photo = null;
      if (formik.touched.photo && formik.values.photoFile && typeof formik.values.photoFile !== 'string') {
        photo = await uploadPhoto(formik.values.photoFile as File);
      }

      if (!photo) {
        formik.setErrors({ photo: 'Fout bij uploaden afbeelding. Probeer het nog eens.' });
        return;
      }

      const response = await axios.post(
        url,
        { ...formik.values, photo },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.data) {
        setActivity(response.data);
        navigate('/activities/success', {
          state: { name: formik.values.name },
        });
      }
    } catch (err) {
      const axiosError = err as AxiosError<{ code: string; message: string; field: string }[] | ''>;
      if (axiosError.response) {
        // response.data its not always a string! If there are errors, its an array, check typed I added above.
        // Please review if this line is necesarry, if not remove it
        setError(axiosError.response.data as string);

        if (
          axiosError.response.data &&
          axiosError.response.data.length &&
          axiosError.response.data.find((error) => error.code === 'activity_not_in_future')
        ) {
          setTimeError('De tijd die je hebt gekozen ligt in het verleden');
        }

        // APIs is not responding with error message when time is not provided
        if (axiosError.response.data == '' && (startTime == '' || (endDate && endTime == ''))) {
          setTimeError('Tijd is niet ingevuld');
        }
      } else {
        setError(axiosError.message);
      }
      console.error('error: ', err);
    }
  };

  const updateActivity = async () => {
    const url = `${process.env.REACT_APP_API_URL}api/activity`;

    try {
      axios.defaults.withCredentials = true;

      let photo = null;

      if (formik.touched.photo && formik.values.photoFile && typeof formik.values.photoFile !== 'string') {
        photo = await uploadPhoto(formik.values.photoFile as File);
      } else {
        photo = formik.values.photo;
      }

      if (!photo) {
        formik.setErrors({ photo: 'Fout bij uploaden afbeelding. Probeer het nog eens.' });
        return;
      }

      let modifiedFormikValues = {
        ...formik.values,
        id: activityId,
        photo,
      };

      await axios.put(url, modifiedFormikValues, {
        headers: { 'Content-Type': 'application/json' },
      });

      navigate('/activities');
      formik.resetForm({ values: modifiedFormikValues });
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError.response) {
        setError(axiosError.response.data as string);
      } else {
        setError(axiosError.message);
      }
      console.error('error: ', err);
    }
  };

  const handlePhotoChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    selectedFile && formik.setValues({ ...formik.values, photoFile: selectedFile, photo: selectedFile.name });
  };

  return (
    <div>
      {/* Hero Image */}
      <Hero
        image="578A0388.png"
        heading={`Activiteit ${isEditMode ? 'bewerken' : 'toevoegen'}`}
        place="Vitality Experience 2023, Utrecht"
      />

      <div className={`${isMobile ? 'px-6' : 'px-[4rem]'}`}>
        <section className={`h-auto ${isMobile ? 'w-full' : 'max-w-[540px]'} my-12 grid content-center`}>
          <div className={`flex  ${isMobile ? 'w-full justify-start' : ''} items-center mb-4`}>
            <div className="flex row items-center justify-start cursor-pointer" onClick={() => navigate('/activities')}>
              <i className="fa-solid fa-arrow-left pr-2 text-baseBlue500" />
              <p className="text-baseBlue500 font-bold text-lg">Terug</p>
            </div>
          </div>
          <FormikProvider value={formik}>
            <form
              onSubmit={formik.handleSubmit}
              className={`flex flex-wrap ${isMobile ? 'w-full' : 'max-w-[640px]'} justify-between gap-y-4`}
            >
              {/*Title*/}
              <div className={`flex ${isMobile ? 'flex-col' : ' items-center'} w-full mb-4 space-y-2`}>
                <h2 className="basis-1/4 subtitle-extra-bold-text">Titel &#42;</h2>
                <div className={`flex flex-col ${isMobile ? 'w-full' : 'w-[80%] justify-end'} gap-y-2`}>
                  <InputField
                    type="text"
                    placeholder="Voeg een pakkende en duidelijke titel toe"
                    name="name"
                    errorstate={formik.errors.name && formik.touched.name}
                    errormessage={formik.errors.name}
                  />
                </div>
              </div>

              {/* Dates */}
              <div className={`flex ${isMobile ? 'flex-col space-y-2' : 'items-center'} w-full items-baseline mb-2`}>
                <div className="basis-1/4">
                  <h2 className=" subtitle-extra-bold-text">Datum &#42;</h2>
                </div>
                <div className="flex flex-col w-[80%] space-y-2">
                  {/*Start Date*/}
                  <div className={`flex flex-col ${isMobile ? '' : ' items-center'} w-full`}>
                    <div className="flex flex-row w-full space-x-2">
                      <div className="flex flex-col justify-end ">
                        <DatePicker
                          minDate={addDays(new Date(), +0)}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          dateFormat="MMMM d, yyyy"
                          placeholderText="Begindatum"
                          className={`border ${
                            (formik.errors.location && formik.touched.location) || timeError
                              ? 'border-accent-secondary'
                              : 'border-accent-primary'
                          } rounded-xl py-4 px-4 text-sm text-tertiary w-full focus:outline-none border-1 focus:border-blue-700`}
                        />
                      </div>

                      {/* Start time */}
                      <div className="flex flex-col gap-y-2 justify-end w-1/3">
                        <input
                          type="text"
                          value={startTime}
                          onChange={handleTimeChange}
                          maxLength={5}
                          placeholder="Start tijd"
                          className={`border ${
                            (formik.errors.datetimeStart && formik.touched.datetimeStart) || timeError
                              ? 'border-accent-secondary'
                              : 'border-accent-primary'
                          } rounded-xl py-4 px-4 text-sm text-tertiary w-full focus:outline-none border-1 focus:border-blue-700`}
                        />
                      </div>
                    </div>

                    {/* TODO: this should be handled by Formik, in order to do that we could link formik to date and time separately and merge them on submiting and not before */}
                    {timeError && <div className=" mt-2 mr-2 text-baseStateWarning500">{timeError}</div>}
                  </div>

                  {/*End Date*/}
                  <div className="flex flex-col w-full items-start">
                    <h2 className="basis-1/4 subtitle-extra-bold-text"></h2>
                    <div className="flex flex-row space-x-2 w-full">
                      <div className="flex flex-col justify-end">
                        {!noEndDate && (
                          <DatePicker
                            minDate={addDays(new Date(), +0)}
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="MMMM d, yyyy"
                            placeholderText="Einddatum"
                            className={`border ${
                              (formik.errors.location && formik.touched.location) || timeError
                                ? 'border-accent-secondary'
                                : 'border-accent-primary'
                            } rounded-xl py-4 px-4 text-sm text-tertiary w-full focus:outline-none border-1 focus:border-blue-700`}
                          />
                        )}
                      </div>

                      {/* End time */}

                      {!noEndDate && (
                        <div className="flex flex-col gap-y-2 justify-end w-1/3">
                          <input
                            type="text"
                            value={endTime}
                            onChange={handleEndTimeChange}
                            maxLength={5}
                            placeholder="End tijd"
                            className={`border ${
                              (formik.errors.datetimeEnd && formik.touched.datetimeEnd) || timeError
                                ? 'border-accent-secondary'
                                : 'border-accent-primary'
                            } rounded-xl py-4 px-4 text-sm text-tertiary w-full focus:outline-none border-1 focus:border-blue-700`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/*No end time*/}
              <div className={`flex w-full ${isMobile ? 'justify-start' : 'items-center'} `}>
                {/* {!isMobile && <div className="basis-1/4"></div>} */}
                <div className={`flex  ${isMobile ? 'w-full' : 'w-[75%]'} ml-auto justify-end items-center`}>
                  <div className="toggle w-full">
                    <label htmlFor="no-endtime" className="text-tertiary">
                      <span className="pr-2">Geen eindtijd</span>
                      <input
                        id="no-endtime"
                        type="checkbox"
                        name="no_end_time"
                        onChange={handleCheckboxChange}
                        checked={noEndDate}
                      />
                    </label>
                  </div>
                </div>
              </div>
              {/*Location*/}
              <div className={`flex ${isMobile ? 'flex-col' : 'flex-row items-center'} w-full space-y-2 mb-4`}>
                <h2 className="basis-1/4 subtitle-extra-bold-text">Locatie &#42;</h2>
                <div className={`flex flex-col ${isMobile ? 'w-full' : 'w-[80%]'} gap-y-2 justify-end`}>
                  <SearchPlace onPlaceChange={handlePlaceChange} initialValue={formik.values.location} />
                </div>
              </div>

              {/*Description*/}
              <div className={`flex ${isMobile ? 'flex-col' : 'flex-row items-start'} w-full space-y-2 mb-4`}>
                <div className="flex flex-col basis-1/4 items-start">
                  <h2 className="subtitle-extra-bold-text">Omschrijving &#42;</h2>
                  {/*
                    <p className="small-caption-text text-baseBlue500">
                      Toon voorbeelden
                    </p>
                    */}
                </div>
                <div className={`flex flex-col ${isMobile ? 'w-full' : 'w-[80%]'} justify-end`}>
                  <textarea
                    id="tekens"
                    name="description"
                    value={formik.values.description ? formik.values.description : ''}
                    placeholder="Vertel kluppers duidelijk en zo compleet mogelijk wat ze van jouw activiteit kunnen verwachten."
                    className="text-tertiary h-[129px] rounded-xl p-3 text-[17px] w-full focus:outline-none border-1 overflow-y-hidden leading-tight  border-accent-primary border"
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.handleChange(event);
                    }}
                  ></textarea>

                  {formik?.errors?.description && formik?.touched?.description && (
                    <div className="text-right text-baseStateError500">{formik.errors.description}</div>
                  )}
                </div>
              </div>

              {/*Club Advantage*/}
              <div className={`flex space-y-2 mb-4 w-full ${isMobile ? ' flex-col' : ''}`}>
                <div className="flex flex-col basis-1/4">
                  <h2 className="subtitle-extra-bold-text text-wrap">Voordeel voor kluppers &#42;</h2>
                </div>
                <div className={`flex flex-col gap-y-2 ${isMobile ? 'w-full' : 'w-[80%]'} justify-end`}>
                  <textarea
                    id="tekens"
                    name="benefit"
                    value={formik.values.benefit ? formik.values.benefit : ''}
                    placeholder="Vertel kluppers wat het voordeel is dat jij hen biedt. Beschrijf de korting of het extraatje. "
                    className="text-tertiary h-[129px] rounded-xl p-3 text-[17px] w-full focus:outline-none  overflow-y-hidden leading-tight border-accent-primary border"
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.handleChange(event);
                    }}
                  ></textarea>
                  {formik?.errors?.benefit && formik?.touched?.benefit && (
                    <div className="text-right text-baseStateError500">{formik.errors.benefit}</div>
                  )}
                </div>
              </div>

              {/*Interests */}
              <div className={`flex w-full space-y-2 mb-4 ${isMobile ? 'flex-col' : 'flex-row items-center'}`}>
                <div className="flex flex-col basis-1/4">
                  <h2 className="subtitle-extra-bold-text">Soort activiteiten &#42;</h2>
                  <p className="small-caption-text text-tertiary">(1 tot 3)</p>
                </div>
                <div className={`flex flex-col gap-y-2 ${isMobile ? 'w-full' : 'w-[80%]'} justify-end`}>
                  <div role="group" className="flex items-center flex-wrap gap-x-2 gap-y-3">
                    {interestsItems.map((item) => (
                      <label
                        key={item.id}
                        className={`cursor-pointer ${
                          formik.values.interestIds.includes(item.id) ? 'bg-powderBlue' : 'bg-grayTone100'
                        } px-3 py-2 font-bold rounded-3xl text-grayTone700`}
                      >
                        <Field
                          className={`peer hidden `}
                          type="checkbox"
                          name="interests"
                          value={item.id.toString()}
                          checked={formik.values.interestIds.includes(item.id)}
                          onBlur={formik.handleBlur}
                          onChange={(e: any) => {
                            if (e.target.checked && formik.values.interestIds.length < 3) {
                              formik.setFieldValue('interestIds', [...formik.values.interestIds, item.id]);
                            } else {
                              formik.setFieldValue(
                                'interestIds',
                                formik.values.interestIds.filter((interest) => interest !== item.id)
                              );
                            }
                          }}
                        />
                        <div className="flex items-center gap-x-2">
                          {formik.values.interestIds.includes(item.id) ? (
                            <img src="/icons/check_circle.svg" />
                          ) : (
                            <img src="/icons/add_circle.svg" />
                          )}
                          <p className={`${formik.values.interestIds.includes(item.id) ? 'text-baseBlue500' : ''}`}>
                            {item.name}
                          </p>
                        </div>
                      </label>
                    ))}
                  </div>
                </div>
              </div>

              {/*maxPeople*/}
              <div className={`flex w-full space-y-2 mb-4 ${isMobile ? 'flex-col' : 'flex-row items-center'}`}>
                <h2 className="basis-1/4 subtitle-extra-bold-text">Aantal deelnemers &#42;</h2>
                <div className="flex flex-col w-1/5 gap-y-2">
                  <InputField
                    type="number"
                    name="maxPeople"
                    errorstate={formik.errors.maxPeople && formik.touched.maxPeople}
                    errormessage={formik.errors.maxPeople}
                    min={2}
                    defaultValue={''}
                  />
                </div>
              </div>

              {/* Price
                {!gratis &&
                <div className="flex w-full items-center ">
                  <h2 className="basis-1/4 subtitle-extra-bold-text">Prijs &#42;</h2>
                  <div className="flex flex-col w-1/5 gap-y-2">
                    <InputField
                        type="text"
                        name="price"
                        errorstate={formik.errors.price && formik.touched.price}
                        errormessage={formik.errors.price}
                    />
                  </div>
                </div>
                }
                */}

              {/* Gratis
                <div className="flex w-full items-center">
                  <h2 className="basis-1/4 subtitle-extra-bold-text"></h2>
                  <div className="flex w-[80%] justify-end items-center">
                    <div className="toggle w-full">
                      <label htmlFor="one" className="text-tertiary">
                        <span className="pr-2">Gratis</span>
                        <input id="one" type="checkbox" name="gratis"  onChange={handleGratisChange}/>
                      </label>
                    </div>
                  </div>
                </div>
                */}

              {/* Partner Type*/}
              <div
                className={`flex ${
                  isMobile ? 'flex-col' : 'flex-row  items-center'
                } justify-between w-full flex-nowrap space-y-2 mb-4`}
              >
                <p className="subtitle-extra-bold-text">Partner type &#42;</p>
                <div className="flex gap-x-2 radio">
                  {radioOptions.map((option, index) => (
                    <label
                      key={index}
                      className={`flex w-[50%] items-center gap-x-5 text-lg border p-3 rounded-xl border-accent-primary text-tertiary ${
                        formik.values.vitalityExperience === option.value
                          ? 'bg-accent-primary_hover'
                          : 'border-accent-primary'
                      }`}
                    >
                      <img className={`${isMobile ? 'w-8' : 'w-10'} rounded-lg`} src={option.logo} alt="" />
                      <input
                        type="radio"
                        name="vitalityExperience"
                        value={option.value.toString()}
                        checked={formik.values.vitalityExperience === option.value}
                        onChange={(e) => formik.setFieldValue('vitalityExperience', JSON.parse(e.currentTarget.value))}
                      />
                      <span>{option.key}</span>
                    </label>
                  ))}
                </div>
              </div>

              {/* Photo upload */}
              <div className={`flex w-full space-y-2 ${isMobile ? 'flex-col' : 'flex-row items-baseline'} `}>
                <h2 className="basis-1/3 subtitle-extra-bold-text">Omslagfoto &#42;</h2>
                <div className="relative inline-block w-full">
                  <label className="cursor-pointer">
                    <input
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      name="photo"
                      className="absolute z-10 opacity-0 cursor-pointer"
                      onChange={handlePhotoChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.photo && formik.errors.photoFile ? <div>{formik.errors.photoFile}</div> : null}
                    <div className="py-6 px-4 border border-accent-primary rounded-lg flex items-center justify-between space-x-2">
                      <div>
                        <i className="fa-solid fa-image fa-xl pr-2 text-tertiary" />
                        {/*@ts-ignore*/}
                        <span className="text-tertiary">
                          {formik.values.photo ? extractFilename(formik.values.photo) : ''}
                        </span>
                      </div>
                      <div>
                        {formik.values.photo && !formik.errors.photoFile && (
                          <i className="fa fa-check-circle text-green-500" />
                        )}
                      </div>
                    </div>
                  </label>
                  <p className="my-2 ml-3 note-text-light text-tertiary">Max 2 MB, alleen jpg of png.</p>
                </div>
              </div>

              <div className="flex w-full justify-end gap-x-4">
                {/* <button type="button" className="w-auto" onClick={() => navigate(-1)}>
                  <div
                    className={`${
                      isMobile ? 'px-4' : 'pl-10 pr-12'
                    } my-4 w-full rounded-xl py-4  max-w-[540px] h-[58px] text-center cursor-pointer font-bold bg-none border-4 border-grayTone500 text-grayTone500 my-4 w-full rounded-xl text-center cursor-pointer font-bold`}
                  >
                    Annuleren
                  </div>
                </button> */}

                <button
                  type={'button'}
                  className={`${isMobile ? 'w-[50%]' : 'min-w-[189px] w-auto'}`}
                  onClick={() => navigate(-1)}
                >
                  <div
                    className={`my-4 w-full flex justify-center items-center rounded-xl max-w-[540px] h-[58px] text-center cursor-pointer font-bold border-grayTone500 text-grayTone500 border-4`}
                  >
                    <>Annuleren</>
                  </div>
                </button>

                <SubmitButton
                  onSubmit={isEditMode ? updateActivity : createActivity}
                  text={isEditMode ? 'Wijzigingen opslaan' : 'Publiceer'}
                  disabled={formik.dirty && formik.isValid}
                  icon={true}
                  type="button"
                />
              </div>
              {error && (
                <div className=" mt-2 mr-2 text-baseStateWarning500">
                  Er is een fout opgetreden bij het verwerken van uw formulier. Probeer het later opnieuw.
                </div>
              )}
            </form>
          </FormikProvider>
        </section>
      </div>
    </div>
  );
};
export default CreateActivityForm;
