import React, { useEffect, useState } from 'react';
import BlogCard from './BlogCard';
import MessagesCard from './MessagesCard';
import ActivitiesCard from './ActivitiesCard';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import { getToken, getUserId } from '../../utils/helpers';
import { IPartner } from '../../modals/interfaces';
import { IActivities } from '../../modals/Activities';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Message } from '../messages/messages.model';

const Dashboard = () => {
  const isMobile = useIsMobile();
  const isLargeScreen = useIsMobile('(min-width: 1280px)');
  const [plannedActivities, setPlannedActivities] = useState<any[]>([]);

  const [unreadMessages, setUnreadMessages] = useState<Message[]>([]);

  useEffect(() => {
    const getPartnerData = async () => {
      const url = `${process.env.REACT_APP_API_URL}api/activity/all`;

      try {
        axios.defaults.withCredentials = true;

        let config = {
          method: 'get',
          origin: true,
          credentials: true,
          url: url,
          headers: {
            'content-Type': 'application/json',
          },
        };

        axios
          .request(config)
          .then((response) => {
            setPlannedActivities(response.data ?? []);
          })
          .catch((error) => {
            console.error('Error fetching data: ', error);
          });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    getPartnerData();
  }, []);

  const getUnreadMessages = async () => {
    const url = `${process.env.REACT_APP_API_URL}api/notification/unread`;

    try {
      axios.defaults.withCredentials = true;

      let config = {
        method: 'get',
        origin: true,
        credentials: true,
        url: url,
        headers: {
          'content-Type': 'application/json',
        },
      };

      axios
        .request(config)
        .then((response) => {
          setUnreadMessages(response.data.content);
        })
        .catch((error) => {
          console.error('Error fetching data: ', error);
        });
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  let navigate = useNavigate();
  const [partner, setPartner] = useState<IPartner>();
  const completeProfile = partner ? partner.photo : false;
  let userId = getUserId();

  useEffect(() => {
    const getPartnerData = async () => {
      const url = `${process.env.REACT_APP_API_URL}api/partner/${userId}`;

      try {
        axios.defaults.withCredentials = true;

        let config = {
          method: 'get',
          origin: true,
          credentials: true,
          url: url,
          headers: {
            'content-Type': 'application/json',
          },
        };
        axios
          .request(config)
          .then((response) => {
            setPartner(response.data);
          })
          .catch((error) => {
            console.error('Error fetching data: ', error);
          });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    getPartnerData();
    getUnreadMessages();
  }, []);

  return (
    <div className={`py-14 ${!isLargeScreen ? 'px-6 w-full' : 'px-12 w-3/4'}`}>
      <div>
        <h2 className="h2 mb-8">Hi {partner?.firstName} 👋</h2>
      </div>

      <div className={`${!isLargeScreen ? 'flex flex-col' : ''}`}>
        <div
          className={`flex mb-8 flex-1 ${
            !isLargeScreen ? ' flex-col space-y-8 items-center' : 'flex flex-row space-x-8'
          } ${!isLargeScreen && !isMobile ? 'w-full' : ''}`}
        >
          <div
            className={`flex flex-col justify-start home-card-container bg-baseTonBlue900 text-white p-6 rounded-3xl shadow-xl`}
          >
            <div>
              <h4 className={`h4 text-white mb-6`}>
                {completeProfile ? 'Start nieuwe activiteiten' : '🚨 Let op, bijna klaar...'}
              </h4>
            </div>

            <div className="">
              <p className={`regular-text ${isMobile ? 'mb-10' : 'mb-8'}`}>
                {completeProfile
                  ? 'Wat wordt jouw volgende activiteit? De Vitality Experience komt eraan en kluppers hebben zin er samen op uit te gaan. Succes!'
                  : 'Om je eerste activiteit aan te kunnen maken dien je eerst je profiel af te ronden. Hoe persoonlijker, hoe beter. Succes!'}
              </p>
            </div>
            <div
              onClick={() => (completeProfile ? navigate('/activities/create') : navigate('/profile'))}
              className={`flex items-center justify-center bg-baseYellow500 text-baseTonBlue900
               rounded-lg p-4 w-full h-[58px] text-center cursor-pointer font-black mt-auto`}
            >
              <i className="fa-solid fa-arrow-right pr-2 text-baseYellow900" />
              <span className="font-extrabold text-baseYellow900">
                {completeProfile ? 'Voeg nieuwe activiteit toe' : 'Maak je profiel compleet'}
              </span>
            </div>
          </div>

          <ActivitiesCard plannedActivities={plannedActivities} />

          <MessagesCard
            messages={unreadMessages.filter((item) => item.status !== 'DELETED').splice(0, 3)}
            profileCompleted={true}
          />
        </div>
        <div
          className={`flex ${!isLargeScreen ? 'flex-col space-y-8 items-center' : 'flex-row gap-8'} ${
            !isLargeScreen && !isMobile ? 'w-full' : ''
          } mb-8`}
        >
          <BlogCard
            description="Klup is een hechte community en leden houden van een persoonlijke aanpak. Geen contact met bedrijf X maar liever met Friso van PadelNext. Probeer ook zo je activiteiten aan te maken en bedenk waar je zelf enthousiast van wordt."
            image="/images/578A0034.png"
            title="Persoonlijke aanpak"
            tip="Tip 1"
          />

          <BlogCard
            description="Zowel in de app als op locatie is het belangrijk dat kluppers zich welkom voelen. Het merendeel komt alleen dus houd hier rekening mee. Hoe herkennen ze elkaar en is alles vooraf duidelijk?"
            image="/images/578A0124.png"
            title="Heet kluppers welkom"
            tip="Tip 2"
          />

          <BlogCard
            description="Vooraf kan het goed zijn dat kluppers vragen of reacties hebben. Houd daarom deze portal in de gaten en reageer tijdig. Ook kun je zelf reacties plaatsen om deelnemers te enthousiasmeren. "
            image="/images/578A0398.png"
            title="Vragen en reacties"
            tip="Tip 3"
          />
          {isMobile && (
            <div
              id="wrapper"
              className={`flex flex-row items-center cursor-pointer w-full max-h-[132px] pr-0 bg-gradient-to-r from-baseTonBlue200 from-80% to-baseBlue500 to-80% rounded-2xl shadow-xl `}
              onClick={() => navigate('/support')}
            >
              <div className="flex-col w-[80%] p-4">
                <h4 className={`${isMobile ? 'text-[22px]' : 'text-[24px]'} text-baseBlue500 font-bold`}>Meer tips</h4>
                <p className={`regular-text text-baseTonBlue900 w-full `}>
                  Hier vind je nog meer tips voor het laten slagen van je Klup activiteit met blije kluppers.
                </p>
              </div>

              <div className="flex justify-center w-[20%]">
                <img className={`${isMobile ? 'w-14' : ''}`} src="/icons/Forward_white_arrow.svg" alt="arrow-right" />
              </div>
            </div>
          )}
        </div>
        {!isMobile && (
          <div
            id="wrapper"
            className={`flex flex-row items-center cursor-pointer w-2/3 max-h-[132px] bg-gradient-to-r from-baseTonBlue200 from-80% to-baseBlue500 to-80% rounded-2xl shadow-xl `}
            onClick={() => navigate('/support')}
          >
            <div className="flex-col w-[80%] p-4">
              <h4 className={`${isMobile ? 'text-[22px]' : 'text-[24px]'} text-baseBlue500 font-bold`}>Meer tips</h4>
              <p className={`regular-text text-baseTonBlue900 w-full `}>
                Hier vind je nog meer tips voor het laten slagen van je Klup activiteit met blije kluppers.
              </p>
            </div>

            <div className="flex justify-center w-[20%]">
              <img className={`${isMobile ? 'w-14' : ''}`} src="/icons/Forward_white_arrow.svg" alt="arrow-right" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
